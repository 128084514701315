$student-color-primary: #2C3E50;

#home-student {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: row;
  div.left-container {
   width: 55%;
   padding: 112px 64px;

   h1 {
    font-size: 3.5em;
    font-weight: 100;
    font-family: Roboto, sans-serif;
    color: $student-color-primary;
   }
   p {
     margin: 48px 0;
     font-size: 2em;
     color: $student-color-primary;
   }
    .btn-container {
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        font-family: Roboto, sans-serif;
        max-width: 324px;
        padding: 8px 16px;
        border-radius: 8px;
        &.btn-restart {
          background: #F4F4F4;
          color: $student-color-primary;
          width: 184px;
          margin-right: 48px;
        }
        &.btn-start {
          background: $student-color-primary;
          color: #fff;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
            width: 25px;
            height: 25px;
            border: 3px solid #fff;
            border-radius: 50%;
          }
        }
        &.btn-right {
          width: 264px;
        }
        
      }
    }
  }

  div.right-container {
    width: 45%;
    background: $student-color-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
    }
  }
}