$primary-color: #2C3E50;
$progress-background: #DCEFEF;
$progress-bar: #BAE7E9;
$inactive-title: #828282;
$inactive-bar: #E5E5E5;
$progress-title: #4A7093;
$success-bar: #F9E0A3;
$success-title: #CE800C;

#module-list {
  margin-top: 48px;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  #chatbot-feedback {
    align-self: flex-start;
    width: 50vw;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 0 56px 56px 0;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.2);
    padding: 8px;
    img.chatbot-img {
      max-width: 80px;
      height: auto;
    }
    div.text-content {
      margin-left: 16px;
    }
  }

  #card-container {
    width: calc(100% - 64px);
    display: flex;
    margin: 32px;
    justify-content: center;
    align-items: center;
    .card {
      position: relative;
      width: 280px;
      height: 200px;
      margin: 0 8px 16px 8px;
      border-radius: 24px;
      background: #ececec;
      .title {
        text-align: center;
        padding: 16px 0;
        color: $inactive-title;
        font-size: 1.3em;
        font-weight: bold;

        &.active {
          color: $primary-color;
        }
      }
      .card-content {
        position: absolute;
        width: 100%;
        border-radius: 24px;
        transition: filter 0.5s;
        .bar-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .bar {
            display: flex;
            align-items: center;
            width: 200px;
            height: 48px;
            border-radius: 8px;
            margin: 8px 0;
            background: $inactive-bar;
            color: $inactive-title;
            &.progress-active {
              background: $progress-background;
              color: $progress-title;
            }
            .progress-bar {
              background: $progress-bar;
              height: 100%;
              border-radius: inherit;
              display: flex;
              align-items: center;
              
              .text-content {
                width: 100%;
                padding: 8px 8px;
                color: inherit;
              }
            }
            &.success-rate-active {
              background: $success-bar;
              color: $success-title;
              border-radius: 8px;
              span {
                padding: 8px;
              }
            }                      
            .text-content {
              width: 100%;
              padding: 8px 8px;
              color: inherit;
            }
          }
        }
      }
      .card-modal {
        position: absolute;
        
        width: 100%;
        margin: 24px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: filter 0.25s;
        .btn-modal {
          color: #fff;
          padding: 24px 40px;
          background: $primary-color;
          border-radius: 16px;
          opacity: 0;
          transition: opacity 0.25s;
        }
      }
    }
    .card:hover {
      .card-content {
        filter: blur(4px);
        transition: filter 0.25s;
      }
      .card-modal {
        
        .btn-modal {
          opacity: 1;
  
        }
      }
    }
    .card:nth-child(odd) {
      align-self: flex-end;
    }
  }
}